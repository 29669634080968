export const MergeBase64Fn = (imagesBase64: string[]): Promise<string> => {
  return new Promise((resolve, reject) => {
    if (!imagesBase64.length) {
      return reject("Failed to get canvas context");
    }
    if (imagesBase64.length === 1) {
      resolve(imagesBase64[0]);
    }

    const images = imagesBase64.map((base64) => {
      const img = new Image();
      img.src = `data:image/jpeg;base64,${base64}`;
      return img;
    });

    Promise.all(
      images.map(
        (img) =>
          new Promise<void>((res) => {
            img.onload = () => res();
          })
      )
    ).then(() => {
      // 1. 计算 Canvas 尺寸
      const totalWidth = Math.max(...images.map((img) => img.width));
      const totalHeight =
        images.reduce((sum, img) => sum + img.height, 0) +
        (images.length > 1 ? images.length - 1 : 0); // 分割线仅在多张图片时增加

      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      if (!ctx) return;

      canvas.width = totalWidth;
      canvas.height = totalHeight;

      ctx.fillStyle = "#ffffff"; // 背景色
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // 2. 绘制图片
      let yOffset = 0;
      images.forEach((img, index) => {
        const xOffset = (totalWidth - img.width) / 2;
        ctx.drawImage(img, xOffset, yOffset); // 绘制当前图片
        yOffset += img.height;

        // 如果不是最后一张，绘制分割线
        if (images.length > 1 && index < images.length - 1) {
          ctx.fillStyle = "#c4c4c4";
          ctx.fillRect(0, yOffset, totalWidth, 1); // 分割线
          yOffset += 1; // 分割线高度
        }
      });

      // 3. 导出合并后的图片
      resolve(canvas.toDataURL("image/jpeg").split(",")[1]);
    });
  });
};
