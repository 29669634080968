import { PrinterModel } from "@native/printer/interfaces";
import { printModes } from "../mode.constant";

export const EpsonModel: Record<string, PrinterModel> = {
  TM_M10: {
    vendor: "EPSON",
    name: "TM_M10",
    code: "TM_M10",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_M30: {
    vendor: "EPSON",
    name: "TM_M30",
    code: "TM_M30",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_P20: {
    vendor: "EPSON",
    name: "TM_P20",
    code: "TM_P20",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_P60: {
    vendor: "EPSON",
    name: "TM_P60",
    code: "TM_P60",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_P60II: {
    vendor: "EPSON",
    name: "TM_P60II",
    code: "TM_P60II",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_P80: {
    vendor: "EPSON",
    name: "TM_P80",
    code: "TM_P80",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_T20: {
    vendor: "EPSON",
    name: "TM_T20",
    code: "TM_T20",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_T60: {
    vendor: "EPSON",
    name: "TM_T60",
    code: "TM_T60",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_T70: {
    vendor: "EPSON",
    name: "TM_T70",
    code: "TM_T70",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_T81: {
    vendor: "EPSON",
    name: "TM_T81",
    code: "TM_T81",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_T82: {
    vendor: "EPSON",
    name: "TM_T82",
    code: "TM_T82",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_T83: {
    vendor: "EPSON",
    name: "TM_T83",
    code: "TM_T83",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_T83III: {
    vendor: "EPSON",
    name: "TM_T83III",
    code: "TM_T83III",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_T88: {
    vendor: "EPSON",
    name: "TM_T88",
    code: "TM_T88",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_T90: {
    vendor: "EPSON",
    name: "TM_T90",
    code: "TM_T90",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_T90KP: {
    vendor: "EPSON",
    name: "TM_T90KP",
    code: "TM_T90KP",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_T100: {
    vendor: "EPSON",
    name: "TM_T100",
    code: "TM_T100",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_U220: {
    vendor: "EPSON",
    name: "TM_U220",
    code: "TM_U220",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_U330: {
    vendor: "EPSON",
    name: "TM_U330",
    code: "TM_U330",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_L90: {
    vendor: "EPSON",
    name: "TM_L90",
    code: "TM_L90",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_H6000: {
    vendor: "EPSON",
    name: "TM_H6000",
    code: "TM_H6000",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_M30II: {
    vendor: "EPSON",
    name: "TM_M30II",
    code: "TM_M30II",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TS_100: {
    vendor: "EPSON",
    name: "TS_100",
    code: "TS_100",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_M50: {
    vendor: "EPSON",
    name: "TM_M50",
    code: "TM_M50",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_T88VII: {
    vendor: "EPSON",
    name: "TM_T88VII",
    code: "TM_T88VII",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_L90LFC: {
    vendor: "EPSON",
    name: "TM_L90LFC",
    code: "TM_L90LFC",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  EU_M30: {
    vendor: "EPSON",
    name: "EU_M30",
    code: "EU_M30",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_L100: {
    vendor: "EPSON",
    name: "TM_L100",
    code: "TM_L100",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_P20II: {
    vendor: "EPSON",
    name: "TM_P20II",
    code: "TM_P20II",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_P80II: {
    vendor: "EPSON",
    name: "TM_P80II",
    code: "TM_P80II",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_M30III: {
    vendor: "EPSON",
    name: "TM_M30III",
    code: "TM_M30III",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_M50II: {
    vendor: "EPSON",
    name: "TM_M50II",
    code: "TM_M50II",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_M55: {
    vendor: "EPSON",
    name: "TM_M55",
    code: "TM_M55",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
  TM_U220II: {
    vendor: "EPSON",
    name: "TM_U220II",
    code: "TM_U220II",
    modes: [printModes.base64, printModes.text],
    lineWrap: {
      idx: 0.495,
      odd: true,
      max: 29,
    },
  },
};
