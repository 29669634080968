import { Observable } from "rxjs";
import {
  ThermalPrinterPlugin,
  Printer,
  ErrorResult,
} from "thermal-printer-cordova-plugin-large-image/src";
import { IPrinter } from "./interfaces/printer";
declare let ThermalPrinter: ThermalPrinterPlugin;

declare let Ping: any;
export const requestBTPermission = () => {
  ThermalPrinter.requestBTPermissions(
    { type: "bluetooth", id: "xxx" },
    function (result) {
      console.log(result);
    },
    function (error) {
      console.log(error);
    }
  );
};

export const checkUsbPermission = () => {
  return new Observable((o) => {
    ThermalPrinter.listPrinters(
      { type: "usb" },
      (list: Printer[]) => {
        const printer = list.find((p) => p.deviceId);
        if (printer) {
          requestUsbPermission(printer).then((res) => {
            if (res) {
              o.next(true);
            } else {
              o.next(false);
            }
          });
        } else {
          o.next(false);
        }
      },
      (err: ErrorResult) => {
        console.log(err);
        o.next(false);
      }
    );
  });
};

export const requestUsbPermission = (printer: Printer) => {
  return new Promise((resolve) => {
    ThermalPrinter.requestPermissions(
      { type: "usb", id: printer.deviceId },
      function (result) {
        console.log(result);
        if (result.granted) {
          resolve(true);
        } else {
          resolve(false);
        }
      },
      function (error) {
        console.log(error);
        resolve(false);
      }
    );
  });
};

export const checkTCPStatus = (printer: IPrinter) => {
  console.log(printer);

  return new Observable((o) => {
    const ipList = [
      {
        query: printer.tcpAddress,
        timeout: 1,
        retry: 3,
        version: "v4",
      },
    ];

    const p = new Ping();
    p.ping(
      ipList,
      (results) => onPingSucceed(o, results),
      (error) => onPingFailed(o, error)
    );
  });
};

const onPingSucceed = (observer, results) => {
  if (results[0]?.response?.status === "success") {
    observer.next(true);
  } else {
    observer.next(false);
  }
};

const onPingFailed = (observer, error) => {
  console.log(error);
  observer.next(false);
};
