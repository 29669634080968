import { PrinterModel, PrinterVendorCode } from "../interfaces";
import { Emulation } from "../interfaces/printer";
import { EpsonModel } from "./epson";

import { printModes } from "./mode.constant";
import { StarModel } from "./star";

export const printerModels: Record<
  "browser" | "sunmi" | "star" | "bixolon" | "escpos" | "epson",
  Record<string, PrinterModel>
> = {
  browser: {
    webPrint: {
      vendor: "BROWSER",
      name: "Web Print",
      code: "browser",
      modes: [printModes.text],
      lineWrap: {
        idx: 0.495,
        odd: true,
        max: 29,
      },
    },
  },
  sunmi: {
    inner: {
      vendor: "SUNMI",
      name: "Inner",
      code: "INNER",
      modes: [printModes.base64, printModes.text],
      lineWrap: {
        idx: 0.495,
        odd: true,
        max: 29,
      },
    },
    kitchen80: {
      vendor: "SUNMI",
      name: "Kitchen 80",
      code: "Kitchen80",
      modes: [printModes.base64, printModes.text],
      lineWrap: {
        idx: 0.495,
        odd: true,
        max: 29,
      },
    },
  },
  star: StarModel,
  epson: EpsonModel,
  bixolon: {},
  escpos: {
    std: {
      vendor: "ESCPOS",
      name: "EscPos",
      code: "std",
      modes: [printModes.base64, printModes.text],
      lineWrap: {
        idx: 0.495,
        odd: true,
        max: 29,
      },
    },
  },
} as const;

export const PrinterVendorModelList: Record<PrinterVendorCode, PrinterModel[]> =
  {
    BROWSER: [printerModels.browser.webPrint],
    SUNMI: [printerModels.sunmi.inner],
    EPSON: parseModelList(printerModels.epson),
    ESCPOS: [printerModels.escpos.std],
    STAR: parseModelList(printerModels.star),
    BIXOLON: [],
  };

export function parseModelList(object: Record<string, PrinterModel>) {
  const list: PrinterModel[] = [];
  for (const key in object) {
    if (Object.prototype.hasOwnProperty.call(object, key)) {
      const element = object[key];
      list.push(element);
    }
  }
  return list;
}
