import { PrinterModel } from "@native/printer/interfaces";
import { pinterStarEmulations } from ".";
import { printModes } from "../mode.constant";

export const StarModel: Record<string, PrinterModel> = {
  mPOP: {
    vendor: "STAR",
    name: "mPOP",
    code: "mPOP",
    emulation: pinterStarEmulations.starPRNT,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  fvp10: {
    vendor: "STAR",
    name: "FVP10",
    code: "FVP10",
    emulation: pinterStarEmulations.starLine,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },

  tsp100: {
    vendor: "STAR",
    name: "TSP100",
    code: "TSP100",
    emulation: pinterStarEmulations.starGraphic,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  tsp650II: {
    vendor: "STAR",
    name: "TSP650II",
    code: "TSP650II",
    emulation: pinterStarEmulations.starLine,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  tsp700II: {
    vendor: "STAR",
    name: "TSP700II",
    code: "TSP700II",
    emulation: pinterStarEmulations.starLine,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  tsp800II: {
    vendor: "STAR",
    name: "TSP800II",
    code: "TSP800II",
    emulation: pinterStarEmulations.starLine,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  sp700: {
    vendor: "STAR",
    name: "SP700",
    code: "SP700",
    emulation: pinterStarEmulations.starDotImpact,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  smS210i: {
    vendor: "STAR",
    name: "SM-S210i",
    code: "SM-S210i",
    emulation: pinterStarEmulations.escPosMobile,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  smS220i: {
    vendor: "STAR",
    name: "SM-S220i",
    code: "SM-S220i",
    emulation: pinterStarEmulations.escPosMobile,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  smS230i: {
    vendor: "STAR",
    name: "SM-S230i",
    code: "SM-S230i",
    emulation: pinterStarEmulations.escPosMobile,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  smT300iT300: {
    vendor: "STAR",
    name: "SM-T300i/T300",
    code: "SM-T300i/T300",
    emulation: pinterStarEmulations.escPosMobile,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  smT400i: {
    vendor: "STAR",
    name: "SM-T400i",
    code: "SM-T400i",
    emulation: pinterStarEmulations.escPosMobile,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  smL200: {
    vendor: "STAR",
    name: "SM-L200",
    code: "SM-L200",
    emulation: pinterStarEmulations.starPRNTL,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  smL300: {
    vendor: "STAR",
    name: "SM-L300",
    code: "SM-L300",
    emulation: pinterStarEmulations.starPRNT,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  bsc10: {
    vendor: "STAR",
    name: "BSC10",
    code: "BSC10",
    emulation: pinterStarEmulations.escPos,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  smS210iStarPRNT: {
    vendor: "STAR",
    name: "SM-S210i StarPRNT",
    code: "SM-S210i StarPRNT",
    emulation: pinterStarEmulations.starPRNT,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  smS220iStarPRNT: {
    vendor: "STAR",
    name: "SM-S220i StarPRNT",
    code: "SM-S220i StarPRNT",
    emulation: pinterStarEmulations.starPRNT,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  smS230iStarPRNT: {
    vendor: "STAR",
    name: "SM-S230i StarPRNT",
    code: "SM-S230i StarPRNT",
    emulation: pinterStarEmulations.starPRNT,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  smT300iT300StarPRNT: {
    vendor: "STAR",
    name: "SM-T300i/T300 StarPRNT",
    code: "SM-T300i/T300 StarPRNT",
    emulation: pinterStarEmulations.starPRNT,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
  smT400iStarPRNT: {
    vendor: "STAR",
    name: "SM-T400i StarPRNT",
    code: "SM-T400i StarPRNT",
    emulation: pinterStarEmulations.starPRNT,
    modes: [printModes.base64, printModes.raster],
    lineWrap: {
      idx: 0.606,
    },
  },
};
