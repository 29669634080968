import { EventEmitter, Injectable } from "@angular/core";
import { printerEvents, printerVendors } from "@native/printer/constants";
import { IPrinterEvent, PrinterEvent } from "@native/printer/interfaces";
import { IPrinter } from "@native/printer/interfaces/printer";
import {
  DiscoveryResult,
  EpsonEpos,
  EpsonEposPrinterSerie,
  PrintInstruction,
} from "capacitor-plugin-epson-epos";
import { Observable, Subscriber } from "rxjs";
import { v1 as uuidv1 } from "uuid";
import { IPrintImageObj, IPrintJob, IPrintObj } from "../interfaces/print";

@Injectable({
  providedIn: "root",
})
export class EpsonEposService {
  private printEvent$: EventEmitter<{
    [key: string]: any;
    event: IPrinterEvent;
  }>;
  async ePosPermission() {
    try {
      const result = await EpsonEpos.requestPermission();
      console.log(result);
    } catch (error) {
      console.error("Permission error:", error);
    }
  }

  search(printer: IPrinter, timeout = 10000): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      const portType = printer.port === "BT" ? "BLUETOOTH" : printer.port;

      EpsonEpos.startDiscovery({
        timeout,
        broadcast: "255.255.255.255", // 广播地址
        portType,
      })
        .then((result) =>
          this.onFnSuccess(printerEvents.PORT_SEARCH_STOP, observer, result)
        )
        .catch((error) =>
          this.onFnFailed(printerEvents.PORT_SEARCH_STOP, observer, error)
        );

      return observer.next({ event: printerEvents.PORT_SEARCH_START });
    });
  }

  printString(
    copy = 1,
    printer: IPrinter,
    data: IPrintObj,
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    const instructions: PrintInstruction[] = [];

    instructions.push({
      addFeedLine: 1,
    });

    if (data.text) {
      instructions.push({
        addText: {
          value: data.text,
        },
      });
    }

    if (data.fontSize) {
      instructions.push({
        addTextSize: [data.fontSize, data.fontSize],
      });
    }

    instructions.push({
      addFeedLine: 1,
    });

    if (data.cutReceipt) {
      instructions.push({
        addCut: "cut_feed",
      });
    }

    return new Observable<IPrinterEvent>((observer) => {
      for (let i = 0; i < copy; i++) {
        EpsonEpos.print({
          target: printer.portName as string,
          instructions,
          modelCode: printer.model.code as EpsonEposPrinterSerie,
        })
          .then((result) =>
            this.onFnSuccess(printerEvents.PRINT_JOB_DONE, observer, result, {
              job,
              index: i,
            })
          )
          .catch((error) =>
            this.onFnFailed(printerEvents.PRINT_JOB_FAILED, observer, error, {
              job,
              index: i,
            })
          );
      }

      observer.next({
        event: printerEvents.PRINT_JOB_SENT,
        message: "Print job sent",
      });
      return observer;
    });
  }

  printImage(
    copy = 1,
    printer: IPrinter,
    data: IPrintImageObj,
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      const instructions: PrintInstruction[] = [];
      const width = data.paperWidth;

      if (data.base64Images?.length) {
        for (const base64String of data.base64Images) {
          instructions.push({
            addBase64Image: {
              value: base64String,
              width,
              mode: "HIGH_DENSITY",
              brightness: 0.5,
            },
          });

          instructions.push({
            addFeedLine: 1,
          });

          if (data.cutReceipt) {
            instructions.push({
              addCut: "cut_feed",
            });
          }
        }
      } else if (data.base64URL) {
        const base64String = data.base64URL;

        instructions.push({
          addBase64Image: {
            value: base64String,
            width,
            mode: "HIGH_DENSITY",
            brightness: 0.5,
          },
        });

        instructions.push({
          addFeedLine: 1,
        });

        if (data.cutReceipt) {
          instructions.push({
            addCut: "cut_feed",
          });
        }
      }

      for (let i = 0; i < copy; i++) {
        EpsonEpos.print({
          target: printer.portName as string,
          instructions,
          modelCode: printer.modelName as EpsonEposPrinterSerie,
        })
          .then((result) => {
            console.log("print result", result);
            if (result.success) {
              this.onFnSuccess(printerEvents.PRINT_JOB_DONE, observer, result, {
                job,
                index: i,
              });
            } else {
              this.onFnFailed(
                printerEvents.PRINT_JOB_FAILED,
                observer,
                result,
                {
                  job,
                  index: i,
                }
              );
            }
          })
          .catch((error) =>
            this.onFnFailed(printerEvents.PRINT_JOB_FAILED, observer, error, {
              job,
              index: i,
            })
          );
      }

      observer.next({
        event: printerEvents.PRINT_JOB_SENT,
        message: "Print job sent",
      });
      return observer;
    });
  }

  private onFnSuccess(
    eventType: PrinterEvent = null,
    observer: Subscriber<IPrinterEvent> = null,
    data?: any,
    params?: any
  ) {
    let event: IPrinterEvent = {};
    switch (eventType) {
      case printerEvents.PORT_SEARCH_STOP:
        event = this.onPortSearchFinished(data);
        break;
      case printerEvents.SERIAL_NUMBER_SEARCH_STOP:
        event = {
          data: {
            printer: {
              serialNumber: data,
            },
          },
        };
        break;

      case printerEvents.VERSION_SEARCH_STOP:
        event = {
          data: {
            printer: {
              version: data,
            },
          },
        };
        break;

      case printerEvents.PRINT_JOB_DONE:
        event = this.onPrintFinished(data, params);
        break;

      case printerEvents.SELF_CHECKING_STOP:
        console.log("self checking", data);
        event = {
          event: eventType,
          message: "Self Checking Succeed",
        };
        break;

      default:
        event = { event: printerEvents.UNKNOWN_EVENT, message: "job_done" };
        break;
    }

    if (observer) {
      observer.next(event);
      return observer;
    }

    this.printEvent$.emit({ event, toast: {} });
  }

  private onPrintFinished(printResult: any, params?: any): IPrinterEvent {
    if (params && params.file) {
      params.file.remove(
        () => {},
        () => {}
      );
    }
    const event = {
      event: printerEvents.PRINT_JOB_DONE,
      message: "print_job done",
      data: { ...printResult, ...params },
    };
    return event;
  }

  private onPortSearchFinished(searchResult: DiscoveryResult): IPrinterEvent {
    let event: IPrinterEvent = {};
    if (searchResult.printers.length > 0) {
      event = {
        event: printerEvents.PORT_SEARCH_STOP,
        message: "print_found",
        data: {
          printers: searchResult.printers.map((item) => {
            return {
              id: uuidv1(),
              vendor: printerVendors.epson,
              modelName: item.PrinterName,
              portName: item.Target,
              macAddress: item.Target,
              usbSerialNumber: null,
              selfChecking: false,
            };
          }),
        },
      };
    } else {
      event = {
        event: printerEvents.PORT_SEARCH_STOP,
        message: "print_not_found",
        data: {
          printers: [],
        },
      };
    }

    return event;
  }

  private onFnFailed(
    eventType: PrinterEvent = null,
    observer: Subscriber<IPrinterEvent> = null,
    error?: any,
    params?: any
  ) {
    const event: IPrinterEvent = {
      event: eventType,
      message: JSON.stringify(error),
      data: params,
    };

    switch (eventType) {
      case printerEvents.PRINT_JOB_FAILED:
        if (params && params.file) {
          params.file.remove(() => {}, this.onFnFailed());
        }
        break;

      default:
        break;
    }

    // this.printEvent$.emit({ event, toast: {} });

    if (observer) {
      return observer.error(event);
    }
  }
}
