import { EventEmitter, Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Observable, Subscriber } from "rxjs";

import { v1 as uuidv1 } from "uuid";
import { printerEvents, printerVendors } from "../constants";
import { IPrinterEvent, IPrintJob, PrinterEvent } from "../interfaces";
import {
  IPrintBarCodeObj,
  IPrinterQRCode,
  IPrintImageObj,
  IPrintObj,
} from "../interfaces/print";

import { PrintEventService } from "../print-event.service";
declare let navigator: any;
@Injectable({
  providedIn: "root",
})
export class SunmiPrinterService {
  private printEvent$: EventEmitter<{
    [key: string]: any;
    event: IPrinterEvent;
  }>;
  constructor(
    private platform: Platform,
    private eventService: PrintEventService
  ) {
    this.printEvent$ = this.eventService.printEvent$;
  }

  search(mokeUp = false): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      if (!this.platform.is("cordova")) {
        if (mokeUp) {
          return observer.next({
            event: printerEvents.PORT_SEARCH_STOP,
            data: {
              printers: [
                {
                  vendor: printerVendors.sunmi,
                  portName: "1",
                  id: uuidv1(),
                },
              ],
            },
          });
        } else {
          return this.cordovaPlatformRequired(
            printerEvents.PORT_SEARCH_STOP,
            observer
          );
        }
      }

      navigator.sunmiInnerPrinter
        .hasPrinter()
        .then(this.onPortSearchFinished(observer));

      return observer.next({ event: printerEvents.PORT_SEARCH_START });
    });
  }

  statusStartListener(): Observable<any> {
    console.log("status start called");

    return new Observable<any>((observer) => {
      if (!this.platform.is("cordova")) {
        return this.cordovaPlatformRequired(
          printerEvents.STATUS_LISTENER_START,
          observer
        );
      }
      navigator.sunmiInnerPrinter
        .printerStatusStartListener()
        .then(this.onFnSuccess(printerEvents.STATUS_LISTENER_START, observer))
        .catch(this.onFnFailed(printerEvents.STATUS_LISTENER_START, observer));

      return observer.next({ name: printerEvents.STATUS_LISTENER_START });
    });
  }

  statusStopListener(): Observable<any> {
    console.log("status stop called");

    return new Observable<any>((observer) => {
      if (!this.platform.is("cordova")) {
        return this.cordovaPlatformRequired(
          printerEvents.STATUS_LISTENER_STOP,
          observer
        );
      }
      navigator.sunmiInnerPrinter
        .printerStatusStopListener()
        .then(this.onFnSuccess(printerEvents.STATUS_LISTENER_STOP, observer))
        .catch(this.onFnFailed(printerEvents.STATUS_LISTENER_STOP, observer));

      return observer.next({ name: printerEvents.STATUS_LISTENER_STOP });
    });
  }

  selfChecking(): Observable<any> {
    return new Observable<any>((observer) => {
      if (!this.platform.is("cordova")) {
        return this.cordovaPlatformRequired(
          printerEvents.SELF_CHECKING_STOP,
          observer
        );
      }
      navigator.sunmiInnerPrinter
        .printerSelfChecking()
        .then(this.onFnSuccess(printerEvents.SELF_CHECKING_STOP, observer))
        .catch(this.onFnFailed(printerEvents.SELF_CHECKING_STOP, observer));

      return observer.next({ name: printerEvents.SELF_CHECKING_START });
    });
  }

  getPrinterSerialNo(): Observable<any> {
    return new Observable<any>((observer) => {
      if (!this.platform.is("cordova")) {
        return this.cordovaPlatformRequired(
          printerEvents.SERIAL_NUMBER_SEARCH_STOP,
          observer
        );
      }
      navigator.sunmiInnerPrinter
        .getPrinterSerialNo()
        .then(
          this.onFnSuccess(printerEvents.SERIAL_NUMBER_SEARCH_STOP, observer)
        )
        .catch(
          this.onFnFailed(printerEvents.SERIAL_NUMBER_SEARCH_STOP, observer)
        );

      return observer.next({ name: printerEvents.SERIAL_NUMBER_SEARCH_START });
    });
  }

  getPrinterVersion(): Observable<any> {
    return new Observable<any>((observer) => {
      if (!this.platform.is("cordova")) {
        return this.cordovaPlatformRequired(
          printerEvents.VERSION_SEARCH_STOP,
          observer
        );
      }
      navigator.sunmiInnerPrinter
        .getPrinterVersion()
        .then(this.onFnSuccess(printerEvents.VERSION_SEARCH_STOP, observer))
        .catch(this.onFnFailed(printerEvents.VERSION_SEARCH_STOP, observer));

      return observer.next({ name: printerEvents.VERSION_SEARCH_START });
    });
  }

  printString(
    copy = 1,
    data: IPrintObj,
    job?: IPrintJob
  ): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      if (!this.platform.is("cordova")) {
        const event = {
          name: printerEvents.PRINT_JOB_FAILED,
          message: "cordova_platform_required",
          data: {
            job,
          },
        };

        this.printEvent$.emit({ event, toast: { color: "danger" } });

        return this.cordovaPlatformRequired(
          printerEvents.PRINT_JOB_FAILED,
          observer
        );
      }

      for (let i = 0; i < copy; i++) {
        const counter = i * 1000;
        setTimeout(() => {
          console.log("native called", i, counter);
          if (data.fontSize) {
            navigator.sunmiInnerPrinter.setFontSize(
              data.fontSize,
              () => {},
              () => {}
            );
          }
          navigator.sunmiInnerPrinter
            .printString(data.text)
            .then(
              this.onFnSuccess(printerEvents.PRINT_JOB_DONE, observer, {
                job,
                index: i,
              })
            )
            .catch(
              this.onFnFailed(printerEvents.PRINT_JOB_FAILED, observer, {
                job,
                index: i,
              })
            );
        }, counter);
      }

      observer.next({
        event: printerEvents.PRINT_JOB_SENT,
        message: "Print job sent",
      });
      return observer;
    });
  }

  printImage(data: IPrintImageObj, job?: IPrintJob): Observable<IPrinterEvent> {
    return new Observable<IPrinterEvent>((observer) => {
      if (!this.platform.is("cordova")) {
        const event: IPrinterEvent = {
          event: printerEvents.PRINT_JOB_FAILED,
          message: "cordova_platform_required",
          data: {
            job,
          },
        };

        this.printEvent$.emit({ event, toast: { color: "danger" } });

        return this.cordovaPlatformRequired(
          printerEvents.PRINT_JOB_FAILED,
          observer
        );
      }

      navigator.sunmiInnerPrinter
        .printBitmap(data.base64Image, data.paperWidth, data.paperHeight)
        .then(
          this.onFnSuccess(printerEvents.PRINT_JOB_DONE, observer, {
            file: data.file,
            job,
          })
        )
        .catch(
          this.onFnFailed(printerEvents.PRINT_JOB_FAILED, observer, {
            file: data.file,
            job,
          })
        );

      observer?.next({
        event: printerEvents.PRINT_JOB_SENT,
        message: "Print job sent",
      });
      return observer;
    });
  }

  printBarCode(data: IPrintBarCodeObj, job?: IPrintJob) {
    console.log("printBarCode called");

    return new Observable<any>((observer) => {
      if (!this.platform.is("cordova")) {
        if (!this.platform.is("cordova")) {
          const event: IPrinterEvent = {
            event: printerEvents.PRINT_JOB_FAILED,
            message: "cordova_platform_required",
            data: {
              job,
            },
          };

          this.printEvent$.emit({ event, toast: { color: "danger" } });

          return this.cordovaPlatformRequired(
            printerEvents.PRINT_JOB_FAILED,
            observer
          );
        }
      }

      navigator.sunmiInnerPrinter
        .printBarCode(
          data.text,
          data.symbology,
          data.width,
          data.height,
          data.textPosition
        )
        .then(this.onFnSuccess(printerEvents.PRINT_JOB_DONE, observer, { job }))
        .catch(
          this.onFnFailed(printerEvents.PRINT_JOB_FAILED, observer, { job })
        );

      observer.next({
        name: printerEvents.PRINT_JOB_SENT,
        message: "Print job sent",
      });
      return observer;
    });
  }

  printQrCode(data: IPrinterQRCode, job?: IPrintJob) {
    return new Observable<any>((observer) => {
      if (!this.platform.is("cordova")) {
        if (!this.platform.is("cordova")) {
          const event: IPrinterEvent = {
            event: printerEvents.PRINT_JOB_FAILED,
            message: "cordova_platform_required",
            data: {
              job,
            },
          };

          this.printEvent$.emit({ event, toast: { color: "danger" } });

          return this.cordovaPlatformRequired(
            printerEvents.PRINT_JOB_FAILED,
            observer
          );
        }
      }

      navigator.sunmiInnerPrinter
        .printQRCode(data.text, data.moduleSize, data.errorLevel)
        .then(this.onFnSuccess(printerEvents.PRINT_JOB_DONE, observer, { job }))
        .catch(
          this.onFnFailed(printerEvents.PRINT_JOB_FAILED, observer, { job })
        );

      observer.next({
        name: printerEvents.PRINT_JOB_SENT,
        message: "Print job sent",
      });
      return observer;
    });
  }

  private cleanCache(): void {
    navigator.sunmiInnerPrinter
      .printString(
        "\n\n\n" // "test\n\n\n",
      )
      .then(this.onFnSuccess())
      .catch(this.onFnFailed());
  }

  private onPrintFinished(printResult: any, params: any): IPrinterEvent {
    console.log("print result", printResult);
    this.cleanCache();
    if (params && params.file) {
      params.file.remove(() => {}, this.onFnFailed());
    }
    const event: IPrinterEvent = {
      event: printerEvents.PRINT_JOB_DONE,
      message: "print job done",
      data: { ...printResult, ...params },
    };
    return event;
  }

  private onPortSearchFinished(observer: Subscriber<IPrinterEvent>) {
    return (result) => {
      let event: IPrinterEvent = {};
      if (result) {
        event = {
          event: printerEvents.PORT_SEARCH_STOP,
          message: "Printer found",
          data: {
            printers: [
              {
                id: uuidv1(),
                vendor: printerVendors.sunmi,
                portName: "inner",
                selfChecking: true,
                serialNumber: null,
                version: null,
              },
            ],
          },
        };
      } else {
        event = {
          event: printerEvents.PORT_SEARCH_STOP,
          message: "Printer not found",
          data: {
            printers: [],
          },
        };
      }

      if (observer) {
        observer.next(event);
      }
    };
  }

  private onFnSuccess(
    eventType: PrinterEvent = null,
    observer: Subscriber<IPrinterEvent> = null,
    params: any = null
  ) {
    return (data) => {
      let event: IPrinterEvent = {};
      switch (eventType?.code) {
        case printerEvents.SERIAL_NUMBER_SEARCH_STOP.code:
          event = {
            data: {
              printer: {
                serialNumber: data,
              },
            },
          };
          break;

        case printerEvents.VERSION_SEARCH_STOP.code:
          event = {
            data: {
              printer: {
                version: data,
              },
            },
          };
          break;

        case printerEvents.PRINT_JOB_DONE.code:
          event = this.onPrintFinished(data, params);
          break;

        case printerEvents.SELF_CHECKING_STOP.code:
          console.log("self checking", data);
          event = {
            event: eventType,
            message: "Self Checking Succeed",
          };
          break;

        default:
          event = {
            event: printerEvents.UNKNOWN_EVENT,
            message: "job done",
            data: params,
          };
          break;
      }

      if (observer) {
        observer.next(event);
        // observer.complete();
      }
    };
  }

  private onFnFailed(
    eventType: PrinterEvent = null,
    observer: Subscriber<IPrinterEvent> = null,
    params: any = null
  ) {
    return (error) => {
      const event: IPrinterEvent = {
        event: eventType,
        message: JSON.stringify(error),
        data: params,
      };

      switch (eventType?.code) {
        case printerEvents.PRINT_JOB_FAILED.code:
          if (params && params.file) {
            params.file.remove(() => {}, this.onFnFailed());
          }
          break;

        default:
          break;
      }
      if (observer) {
        observer.error(event);
        // observer.complete();
      }
    };
  }

  private cordovaPlatformRequired(
    eventType: PrinterEvent = null,
    observer: Subscriber<IPrinterEvent>
  ) {
    observer.error({ name: eventType, message: "cordova_platform_required" });
    observer.complete();
    return observer;
  }
}
